.prePortalFormHeader {
  color: var(--new-styles-text-heading, #292a29);
  font-family: Bona Nova;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 360% */
}

.prePortalFormHeaderGreenText {
  color: #7ab495;
}

.prePortalFormCardHeadeText {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.prePortalBullet {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
}
.prevButton {
  border-radius: 5px;
  border: 1px solid #285780;
  background: #fff;
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.nextButton {
  border-radius: 5px;
  background: #285780;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 5px;
}

.inputField {
  font-size: 12px;
  font-family: Poppins;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: #fcfbff;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 22px 24px;
  align-items: center;
  gap: -5px;
}

.inputAntSelect {
  font-size: 12px;
  font-family: Poppins;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  padding-left: 0px;
}

.inputSelect {
  font-size: 12px;
  font-family: Poppins;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: #fcfbff;
  height: 50px;
  padding-left: 22px;
}

.inputRadio {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: var(--blue-gray-50, #f8fafc);
  padding: 12px 16px;
}

.listOfPortalTitle {
  color: #285780;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.tooltips {
  color: #285780c2;
}
