.passwordContainer{
  position: relative;
}
.passwordContainerIcon{
  z-index: 10000000000;
  position: absolute;
  top:12px;
  right:12px;
  color: #2e7dbea6;
}

.passwordIcon{
  zoom:1.2
}