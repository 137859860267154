.navbarcontainerwrapper {
  z-index: 3;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-height: 80px;
  padding: 20px;
}
.linkmie-header {
  display: flex;
  gap: 10px;
}
.favicon-header {
  width: 27px;
}

.textLogo {
  color: #1a3b5f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  /* text-transform: uppercase; */
}

.link {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 20.8px */
  text-decoration: none;
  padding-right: 40px;
  /* text-transform: uppercase; */
}

.linkwrapper {
  padding-top: 5px;
}

.loginBtn {
  max-width: 100%;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 126px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #285780;
  background: #285780;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: none;
}

.loginText {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  text-transform: uppercase;
}

.registetBtn {
  max-width: 100%;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 126px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #285780;
  background: #fff;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: none;
}

.registerText {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-transform: uppercase;
}
