.ant-table-thead .ant-table-cell {
  background-color: #285780 !important;
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.ant-table-tbody {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 150% */
}

.tagText {
  text-transform: capitalize;
}

.selectBtn {
  border-radius: 8px;
  background: #4e8f6f;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
