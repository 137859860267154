.profile-picture {
  position: relative;
  /* width: 172px;
    height: 171px;
    border-radius: 172px;
    border: 8px solid #FFF; */
}
img {
  width: 100%;
}
.edit-icon {
  position: absolute;
  bottom: 10px;
  left: 70%;
  cursor: pointer;
}
.profile-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.profile-name-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  left: 40%;
}
.profile-name-display p:first-child {
  color: #515251;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
}
.profile-name-display p:last-child {
  margin-top: -15px;
  color: #515251;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.profile-edit-btn {
  width: 150px;
  display: flex;
  gap: 10px;
  justify-content: center;
  background: #285780;
  align-items: center;
  color: white;
}
.about-card-details {
  display: flex;
  gap: 40px;
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.about-card-right {
  color: #337f56;
}
.about-right span {
  color: #515251;
}
.recent-activity-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #285780;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.recent-activity-wrapper img {
  width: 50%;
  margin-bottom: 10px;
}
.recent-activity-wrapper p {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 10px;
}
.recent-activity-wrapper span {
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .profile-name-display {
    left: 0;
  }
  .profile-name-display p:first-child {
    font-size: 16px;
  }
  .profile-name-display p:last-child {
    margin-top: -15px;
    font-size: 12px;
  }
  .profile-edit-btn {
    width: 90px;
    font-size: 12px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .profile-edit-btn span img {
    display: none;
  }
  .about-card-details {
    font-size: 12px;
  }
  .recent-activity-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #285780;
    gap: 20px;
  }
  .recent-activity-wrapper img {
    width: 100%;
    margin-bottom: 0px;
  }
  .recent-activity-wrapper p {
    font-size: 12px;
  }
}
