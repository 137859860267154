.advertiser-status-container {
  height: 150px;
  padding-inline: 10px;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  border: 1px solid rgba(40, 87, 128, 0.23);
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
}
.advertiser-status-container p {
  text-align: center;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 30px;
}
.advertiser-status-icon-wrapper {
  margin-top: 28px;
  margin-bottom: 26px;
  border-radius: 10px;
  border: 2px solid white;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advertiser-status-container.id-1 {
  background: #ac7b6b;
}
.advertiser-status-container.id-2 {
  background: #74906f;
}
.advertiser-status-container.id-3 {
  background: #337680;
}
.advertiser-fracture-of-analytics h3 {
  color: #3e4346;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 85.714% */
}
.advertiser-pending-offers h3 {
  color: #3e4346;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 85.714% */
}

.lineThrough {
  text-decoration: line-through;
}

@media screen and (max-width: 359px) {
    .advertiser-status-container {
      padding-inline: 10px;
      height: 170px;
    }
    .advertiser-status-icon-wrapper {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .advertiser-status-container p {
      text-align: center;
      font-size: 13px;
    }
    .publisher-task.advertiser {
      height: 80px;
      padding-top: 15px;
    }
}
@media (min-width: 359px) and (max-width: 768px) {
    .advertiser-status-container {
      padding-inline: 10px;
      height: 170px;
    }
    .advertiser-status-icon-wrapper {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .advertiser-status-container p {
      text-align: center;
      font-size: 13px;
    }
   
}