.adminSiteUserDetailsHeaderText {
  color: #285780;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
}


.adminSiteUserWalletBalanceContainer {
  border-radius: 20px;
  border: 1px solid #285780;
  background: #f7f8fb;
  min-height: 150px;
}

.adminSiteUserBalanceText {
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.adminSiteUserBalanceAmount {
  color: #285780;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 62.4px */
}

.adminSiteUserLifeTimeEarningText {
  color: #85625a;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.adminSiteUserLifeTimeEarningTotal {
  color: #85625a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
}
