.walletTransactionContainer {
  border-radius: 20px;
  background: rgba(217, 217, 217, 0.22);
  min-height: 600px;
  padding: 20px;
}

.walletTransactionHeader {
  color: #515251;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 36.4px */
}

.walletTransactionTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
}

.walletTransactionSubTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.walletTransationIcon {
  color: #285780;
  zoom: 2;
}

.walletTransactionAmount {
  color: #346a4e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
