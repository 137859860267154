.publisher-name {
  color: #3e4346;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 66.667% */
}
.publisher-name-header-wrapper {
  display: flex;
  justify-content: space-between;
}
.publisher-content1 {
  color: #3e4346;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.publisher-content2 {
  color: #285780;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.wallet-wrapper {
  padding: 30px 32px 33px 32px;
  border-radius: 24px;
  border: 1px solid var(--new-styles-grays-outline, #e7e8e7);
  background: #285780;
  box-shadow: 0px 20px 48px 0px rgba(170, 170, 170, 0.29);
  /* margin-right: 20px; */
  margin-bottom: 10px;
}
.wallet-wrapper p {
  color: #b5da9d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.wallet-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount {
  /* width: 100%; */
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}
.toggle-eye-icon {
  font-size: 18px;
}
.wallet-withdraw {
  display: inline-flex;
  padding: 12px 31.5px 12px 33.5px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #f5ffee;
  height: 44px;
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;

  /* Elevations/Dark Mode/Dark 2 */
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}
.profile-status {
  border-radius: 16px;
  border: 1px solid rgba(40, 87, 128, 0.23);
  background: var(--greys-grey-10, #faf9f8);
  padding: 18px 13px 30px 24px;
}
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-header p:first-child {
  color: #201f1d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.profile-header p:last-child {
  color: var(--text-secondary, #605e5c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.profile-bars {
  display: flex;
}
.grey-bar,
.white-bar {
  height: 7px;
}
.grey-bar {
  width: 40%;
  background: #849db3;
}
.white-bar {
  border-radius: 0 43px 43px 0px;
  background: var(--greys-grey-40, #e1dfdd);
  width: 60%;
}
.verify-email {
  display: flex;
  align-items: center;
  gap: 8px;
}

.verify-email span {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: #849db3;
}

.verify-email p {
  height: 24px;
  color: var(--text-secondary, #605e5c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 5px 0;
}

.lineThrough {
  text-decoration: line-through;
}

.complete-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}
.complete-info span {
  width: 24px;
  height: 24px;
  border: 1px solid var(--greys-grey-130, #605e5c);
  border-radius: 48px;
}
.complete-info p {
  color: #849db3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  margin: 5px 0;
}

.publisher-task-wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 30px;
}
.publisher-task-wrapper-left {
  border-radius: 10px;
  border: 3px solid #eccbc1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.publisher-task-wrapper-right {
  border-radius: 10px;
  border: 3px solid #b5daae;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.publisher-task {
  padding: 5px 10px 0px 10px;
  align-items: center;
  display: flex;
  border-radius: 24px;
  border: 1px solid rgba(40, 87, 128, 0.23);
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  height: 95px;
}
.publisher-task-icon {
  margin-right: 15px;
}
.publisher-task-text {
  /* margin-top: 15px; */
  color: #575353;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  margin-right: 20px;
  margin-left: 10px;
  /* line-height: 24px;  */
}
.publisher-create-portal-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 10% 0px;
}
.create-portal-button {
  border-radius: 16px;
  background: #285780;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 1;
  padding: 15px 20px !important;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.portal-share-link {
  background: transparent;
  flex-grow: 1;
  flex-shrink: 1;
}
.portal-share-link-wrapper {
  position: relative;
}
.portal-share-link .ant-input {
  background: transparent !important;
  padding: 10px 0px 0px 0px !important;
}
.portal-share-link .ant-btn {
  background: #285780;
  padding: 32px 10px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -10px; */
}
.portal-share-link .ant-input-affix-wrapper {
  border-radius: 8px 0px 0px 8px !important;
  border: 1px solid #285780 !important;
  padding: 15px;
}
.input-referal-link {
  position: absolute;
  z-index: 3;
  color: #7c7e17;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */
  margin-left: 15px;
}

@media screen and (max-width: 359px) {
  .wallet-wrapper {
    margin-bottom: 10px;
    padding: 15px;
  }
  .amount {
    font-size: 12px;
  }
  .wallet-withdraw {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 8px;
  }
  .toggle-eye-icon {
    font-size: 12px;
  }
  .profile-header p:first-child {
    font-size: 12px;
  }
  .profile-header p:last-child {
    font-size: 12px;
  }
  .publisher-name-header-wrapper {
    flex-direction: column;
  }
  .publisher-content1 {
    font-size: 12px;
  }
  .publisher-content2 {
    font-size: 12px;
  }
  .publisher-task-wrapper {
    gap: 10px;
  }
  .publisher-task-wrapper-left {
    padding: 10px 5px;
  }
  .publisher-task-wrapper-right {
    padding: 10px 5px;
  }
  .publisher-task {
    padding: 2px 5px;
    height: 40px;
  }
  .publisher-task-icon {
    margin-right: 5px;
    width: 100%;
  }
  .publisher-task-icon img {
    /* margin-top: 10px; */
  }
  .publisher-task-text {
    font-size: 10px;
    text-align: center;
  }
  .publisher-create-portal-button-wrapper {
    padding: 0px;
  }
  .recent-activity-header {
    font-size: 20px;
  }
  .recent-activity-background-wrapper {
    padding: 15px 15px;
  }
  .recent-activity-subheader {
    font-size: 12px;
  }
  .recent-activities {
    padding: 5px 10px;
  }
  .recent-activity-timeheader {
    text-align: center;
    padding-inline: 5px;
  }
  .recent-activity-name {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
  .recent-activity-description {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
  .recent-activity-timedisplay {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
}
@media (min-width: 359px) and (max-width: 768px) {
  .wallet-wrapper {
    margin-bottom: 10px;
    padding: 25px;
  }
  .amount {
    font-size: 20px;
  }
  .wallet-withdraw {
    display: inline-flex;
    padding: 12px 15px 12px 15px;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 12px;
  }
  .publisher-name-header-wrapper {
    flex-direction: column;
  }
  .publisher-content1 {
    font-size: 12px;
  }
  .publisher-content2 {
    font-size: 12px;
  }
  .publisher-task-wrapper {
    gap: 10px;
  }
  .publisher-task-wrapper-left {
    padding: 10px 5px;
  }
  .publisher-task-wrapper-right {
    padding: 10px 5px;
  }
  .publisher-task {
    padding: 2px 10px;
    height: 60px;
  }
  .publisher-task-icon {
    margin-right: 5px;
  }
  .publisher-task-icon img {
    /* margin-top: 10%; */
    width: 100%;
  }
  .publisher-task-text {
    font-size: 10px;
    text-align: center;
    margin-top: 15px;
  }
  .publisher-create-portal-button-wrapper {
    padding: 0px;
  }
  .recent-activity-header {
    font-size: 20px;
  }
  .recent-activity-background-wrapper {
    padding: 15px 15px;
  }
  .recent-activity-subheader {
    font-size: 12px;
  }
  .recent-activities {
    padding: 5px 10px;
  }
  .recent-activity-name {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
  .recent-activity-description {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
  .recent-activity-timedisplay {
    margin-top: 5px;
    font-size: 10px;
    padding-inline: 5px;
  }
}
@media screen and (max-width: 369px) {
  #image-1 {
    margin-top: 5px;
  }
  #image-2 {
    margin-top: 10px;
  }
  #image-3 {
    margin-top: 15px;
  }
  #image2-1 {
    margin-top: 5px;
  }
  #image2-2 {
    margin-top: 10px;
  }
  #image2-3 {
    margin-top: 15px;
  }
}

@media (min-width: 370px) and (max-width: 411px) {
  #image-1 {
    margin-top: 0px;
  }
  #image-2 {
    margin-top: 5px;
  }
  #image-3 {
    margin-top: 10px;
  }
  #image2-1 {
    margin-top: 0px;
  }
  #image2-2 {
    margin-top: 5px;
  }
  #image2-3 {
    margin-top: 10px;
  }
}
@media (min-width: 411px) and (max-width: 437px) {
  #image-3 {
    margin-top: 8px;
  }
  #image2-3 {
    margin-top: 8px;
  }
}
@media (min-width: 977px) and (max-width: 1160px) {
  #image-1 {
    margin-top: 5px;
  }
  #image-2 {
    margin-top: 7px;
  }
  #image-3 {
    margin-top: 10px;
  }
  #image2-1 {
    margin-top: 5px;
  }
  #image2-2 {
    margin-top: 3px;
  }
  #image2-3 {
    margin-top: 3px;
  }
  .publisher-task-text {
    font-size: 10px;
    text-align: center;
  }
}
@media (min-width: 1164px) and (max-width: 1300px) {
  #image-1 {
    margin-top: 5px;
  }
  #image-2 {
    margin-top: 7px;
  }
  #image-3 {
    margin-top: 22px;
  }
  #image2-1 {
    margin-top: 5px;
  }
  #image2-2 {
    margin-top: 9px;
  }
  #image2-3 {
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .publisher-task-text {
    font-size: 12px;
    text-align: center;
  }
  .publisher-create-portal-button-wrapper {
    padding: 0px;
  }
  #image2-3 {
    margin-top: 2px;
  }
}

.section-header-user-dashboard {
  color: #3e4346;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
}
