.addPortalButton {
  border-radius: 5px;
  background: #285780;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 5px;
}
