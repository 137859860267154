.portalNameHeader {
  color: #285780;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 109.091% */
}

.portalNameValue {
  color: #36ab6c;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.descriptionText {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}

.otherTextTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.otherTextValue {
  color: #346a4e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}

.backBtn {
  border-radius: 7px;
  border: 1px solid #337f56;
  background: #f7f8fb;
  color: #337f56;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}
