.navbarcontainerwrapper {
  height: 80px;
}

.bottonAdminSideBar {
  width: 100%;
  height: 56px;
  padding: 16px 10px;
  text-align: left;
}

.buttonAdminSideBarSelected {
  background: rgba(240, 247, 235, 0.5);
}

.buttonTextAdminSideBar {
  color: var(--new-styles-text-body-dark, #ffffff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.buttonTextAdminSideBarSelected {
  color: var(--new-styles-primary-eazi-green, #ffffff);
}

.sideBarAdminColor {
  background: #1b2e3e;
  color: white;
}
