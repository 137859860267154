.navbarcontainerwrapper {
  height: 80px;
}

.logoimg {
  height: 40px;
  margin-top: 10px;
  margin-left: 10px;
}

.botton {
  width: 100%;
  height: 56px;
  padding: 16px 10px;
  border-bottom: 0.8px solid var(--new-styles-grays-outline, #e7e8e7);
  text-align: left;
}

.buttonSelected {
  background: var(--new-styles-primary-mint-light, #f6faf2);
}

.buttonText {
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.buttonTextSelected {
  color: var(--new-styles-primary-eazi-green, #11453b);
}
