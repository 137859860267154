.contact-page-wrapper {
    z-index: 1;
}
.contact-page-header {
    margin-top: 30px;
}
.contact-page-head-text {
    color: var(--new-styles-text-heading, #292A29);
    font-family: Bona Nova;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 138.462% */
}
.team {
    color: #B3613F;
    font-family: Bona Nova;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
}
.contact-us-img img{
    width: 70%;
}
/* .contact-us-img{
    width: 400px;
} */
.vector-topLeft{
    top: 30px;
    position: absolute;
    width: 250px;
}
.contact-form {
    margin-top: 70px;
    z-index: 5;
}
.ant-input-affix-wrapper.contact-input {
    border-radius: 10px !important;
    border: 1px solid #0172D2 !important;
    padding: 12px !important;
    /* margin-right: 8px !important;
    margin-top: 10px !important; */
}
.ant-input::placeholder {
    color: rgba(0, 0, 0, 0.50) !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
}
.ant-input-textarea-affix-wrapper.contact-input {
    border-radius: 10px !important;
    border: 1px solid #0172D2 !important;
    padding: 12px !important;
    z-index: 2;
}
.ant-btn.contact-submit-button {
    border-radius: 10px;
    background: #285780;
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    height: 50px !important;
    z-index: 2;
    margin-top: 20px;
    /* padding: 15px 20px !important; */
}
.vector-topRight {
    top: 100px;
    position: absolute;
    width: 280px;
    right: 0;
}
.vector-center{
    top: 800px;
    position: absolute;
    width: 80%;
    z-index: -1;
    /* margin-inline: auto; */
    margin-inline: 10%;
}
.faq-section {
    margin-top: 100px;
}
.faq-heading {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 40px;
}
.faq-heading h2 {
    color: var(--text-color-main-text, #141414);
    font-family: Gilroy-Bold;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 80% */
}
.faq-map-section {
    color: var(--text-color-main-text, #141414);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    cursor: pointer;
}
.faq-answer-section {
    color: var(--text-color-main-text, #141414);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
}
.faq-box {
    margin-bottom: 70px;
}
.symbol {
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .vector-center{
        top: 800px;
        position: absolute;
        width: 95%;
        z-index: -1;
        margin-inline: 2%;
    }
    .contact-page-head-text {
        font-size: 20px;
        line-height: 20px;
    }
    .team {
        font-size: 20px;
        line-height: 50px;
    }
    .contact-us-img img{
        width: 80%;
    }
    .contact-form {
        margin-top: 40px;
    }
    .ant-input-affix-wrapper.contact-input {
        padding: 7px !important;
    }
    .form-second-part{
        margin-top: -13px;
    }
    .ant-input::placeholder {
        font-size: 12px !important;
    }
    .ant-input-textarea-affix-wrapper.contact-input {
        padding: 7px !important;
    }
    .ant-btn.contact-submit-button {
        font-size: 13px;
        z-index: 2;
        margin-top: 20px;
    }
    .vector-topRight {
        top: 100px;
        position: absolute;
        width: 200px;
        right: 0;
    }
    .vector-topLeft{
        top: 30px;
        position: absolute;
        width: 200px;
    }
    .contact-form {
        z-index: 5;
    }
    .faq-section {
        margin-top: 10px;
    }
    .faq-heading {
        margin-bottom: 20px;
    }
    .faq-heading h2 {
        font-size: 20px;
    }
    .faq-map-section {
        font-size: 13px;
    }
    .faq-answer-section {
        font-size: 12px;
    }
    .faq-box {
        margin-bottom: 50px;
    }
}

@media (min-width: 768px) and (max-width: 1110px) {
    .vector-center{
        width: 95%;
        z-index: -1;
        margin-inline: 2%;
    }
}