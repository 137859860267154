.admin-members-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
}
.admin-new-member-header {
  color: #1b2e3e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  margin-bottom: 15px;
}
.admin-member-icon img {
  width: 100%;
}
.admin-member-name {
  color: #1b2e3e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.admin-member-description {
  margin-top: -10px;
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
}
.admin-member-button {
  border: 1px solid #285780;
  background: #fff;
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

@media screen and (max-width: 359px) {
  .admin-members-wrapper {
    padding: 15px 15px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    font-size: 12px;
  }
  .admin-member-description {
    margin-top: -15px;
    font-size: 10px;
  }
  .admin-member-button {
    font-size: 10px !important;
  }
}
@media (min-width: 359px) and (max-width: 440px) {
  .admin-members-wrapper {
    padding: 15px 15px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    font-size: 12px;
  }
  .admin-member-description {
    margin-top: -15px;
    font-size: 10px;
  }
  .admin-member-button {
    font-size: 10px !important;
  }
}
@media (min-width: 440px) and (max-width: 599px) {
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 5px;
    font-size: 13px;
  }
  .admin-member-icon img {
    width: 70%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 13px;
  }
  .admin-member-button {
    margin-top: 10px;
    /* font-size: 10px !important; */
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 10px;
    font-size: 1px;
  }
  .admin-member-icon img {
    width: 70%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 15px;
  }
  .admin-member-button {
    margin-top: 10px;
    /* font-size: 10px !important; */
  }
}
@media (min-width: 701px) and (max-width: 990px) {
  .admin-members-wrapper {
    padding: 15px 15px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 30px;
    font-size: 14px;
  }
  .admin-member-icon img {
    width: 70%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 15px;
  }
  .admin-member-button {
    margin-top: 30px;
    /* font-size: 10px !important; */
  }
}
@media (min-width: 990px) and (max-width: 1000px) {
  .admin-members-wrapper {
    padding: 15px 5px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 0px;
    font-size: 10px;
  }
  .admin-member-icon img {
    width: 100%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 8px;
  }
  .admin-member-button {
    margin-top: 0px;
    font-size: 8px !important;
  }
}
@media (min-width: 999px) and (max-width: 1080px) {
  .admin-members-wrapper {
    padding: 15px 5px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 0px;
    font-size: 10px;
  }
  .admin-member-icon img {
    width: 100%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 8px;
  }
  .admin-member-button {
    margin-top: 0px;
    font-size: 8px !important;
  }
}
@media (min-width: 1080px) and (max-width: 1250px) {
  .admin-members-wrapper {
    padding: 15px 15px;
  }
  .admin-new-member-header {
    font-size: 14px;
  }
  .admin-member-name {
    margin-top: 0px;
    font-size: 10px;
  }
  .admin-member-icon img {
    width: 100%;
  }
  .admin-member-description {
    margin-top: -10px;
    font-size: 8px;
  }
  .admin-member-button {
    margin-top: 0px;
    font-size: 8px !important;
  }
}
