.advertiserContentIntroHeader {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 85.714% */
}
.advertiserContentIntroParagraph {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 150% */
}

.advertiserContentIntroButton {
  border-radius: 5px;
  background: #285780;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 150% */
  margin: 5px;
}
img {
  width: 100%;
}
.referal-left-wrapper {
  border-radius: 20px 0px 0px 20px;
  border: 1px solid #d9d9d9;
  background: #f7f8fb;
  padding: 30px;
}
.referal-right-wrapper {
  border-radius: 0px 20px 20px 0px;
  background: rgba(217, 217, 217, 0.22);
  padding: 30px;
}
.referal-left-header {
  text-align: center;
  color: #346a4e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
.referal-left-content {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 18.2px */
  margin-bottom: 50px;
}
.referal-left-send {
  background: transparent;
}
.referal-left-send .ant-input {
  border: 2px solid #285780;
}
.referal-left-send .ant-btn {
  background: #285780;
  border-radius: 0px;
}
.social-media-flex-wrapper-1 {
  padding-inline: 50px;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.social-media-flex-wrapper-1 img {
  width: 40px;
  margin-bottom: 10px;
}
.social-media-flex-wrapper-1 p {
  color: #285780;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 20.8px */
}
.social-media-flex-wrapper-2 {
  padding-inline: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.social-media-flex-wrapper-2 img {
  width: 40px;
  margin-bottom: 10px;
}
.social-media-flex-wrapper-2 p {
  color: #285780;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 20.8px */
}

.referal-right-header {
  display: flex;
  justify-content: space-between;
}
.referal-right-header p {
  color: #285780;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
.referal-right-header img {
  width: 151px;
}
.referal-user-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.user-image-wrapper {
  background-color: rgba(217, 217, 217, 0.22);
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #285780;
}
.user-image-wrapper img {
  width: 100%;
}
.referal-user-wrapper p {
  margin-top: 10px;
  color: #515251;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}
.referal-stroke {
  height: 80px;
  width: 1px;
  border: 1px solid #285780;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.referal-last-image img {
  margin-top: 10px;
  width: 134px;
}
.total-referal-left {
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 20px 50px;
  gap: 50px;
  border-radius: 20px;
  background: #bee7ed;
}
.total-referal-left img {
  width: 40px;
}
.total-referal-left p {
  margin-top: 13px;
  color: #575353;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.total-referal-right {
  border-radius: 20px;
  border: 1px solid #bee7ed;
  background: #f7f8fb;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 50px;
}
.total-referal-right p {
  color: #515251;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.total-referal-right-wallet-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total-referal-right-wallet-wrapper p {
  /* height: 100%; */
  color: #575353;
  font-family: Poppins;
  font-size: 22px;
  /* margin-top: 15px; */
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 85.714% */
}
.total-referal-image-wrapper {
  margin-top: -15px;
}

@media screen and (max-width: 987px) {
  .total-referal-left {
    margin-right: 0px;
  }
}

.referral-list-header {
  color: #285780;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
