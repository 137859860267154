
.recent-activity-header {
  color: #3E4346;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 75% */
}
.recent-activity-background-wrapper {
  background-color: #E7E8E7;
  border-radius: 5px;
  padding: 15px 20px 20px 20px;
}
.recent-activity-subheader {
  /* margin-top: 25px; */
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.recent-activities-map {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.recent-activities-wrapper {
  display: flex;
  justify-content: space-between;
}
.recent-activities-body-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.recent-activities {
  border-radius: 16px;
  border: 1px solid #E7E8E7;
  background: var(--old-styles-white-color, #FFF);
  padding: 5px 40px;

/* Light Mode/Shadow (MD) */
box-shadow: 0px 9px 18px 0px rgba(170, 170, 170, 0.15);
}
.recent-activity-activity {
  color: var(--new-styles-text-body-normal, #8D8E8D);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 7px;
  margin-left: 17px;
}
.recent-activity-timeheader {
  color: var(--new-styles-text-body-normal, #8D8E8D);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  /* margin-right: 53px; */
}
.recent-activity-name {
  margin-top: 5px;
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.recent-activity-description {
  margin-top: 5px;
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.recent-activity-timedisplay {
  margin-top: 5px;
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
#image2-3 {
  margin-top: 10px;
}