.adevrtiserPreHeader {
  color: #285780;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 46.8px */
}
.adevrtiserSubHeader {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 20.8px */
}

.tipsHeader {
  color: #285780;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 36.4px */
}

.inorderHeader {
  color: #36ab6c;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
}

.offerBullet {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
}

.nextButton {
  border-radius: 5px;
  background: #285780;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 5px;
}
