.walletActionContainer {
  border-radius: 20px;
  background: rgba(217, 217, 217, 0.22);
  min-height: 120px;
  padding: 20px;
}

.walletActionheader {
  color: #515251;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
