.profileCardName {
  color: var(--secblack, #2b2b2b);

  /* Body/Normal/Bold */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.subProfileColor {
  color: var(--prygrey, #898989);
}

.profilePosition {
  color: var(--prygrey, #898989);

  /* Body/Small/2/Medium */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.25px;
}

.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 80px;
}

.topBarOptionMenu {
  position: absolute;
  right: 0px;
  width: 200px;
  margin-block-end: 150px;
  top: 80px;
  max-height: 250px;
  overflow-y: scroll;
}

.topBarNotificationBell {
  color: #898989;
  zoom: 1.6;
}
