.publisher-name {
  color: #3e4346;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 66.667% */
}
.publisher-content {
  color: #3e4346;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.wallet-wrapper {
  padding: 30px 32px 33px 32px;
  border-radius: 24px;
  border: 1px solid var(--new-styles-grays-outline, #e7e8e7);
  background: #285780;
  box-shadow: 0px 20px 48px 0px rgba(170, 170, 170, 0.29);
  /* margin-right: 20px; */
  margin-bottom: 10px;
}
.wallet-wrapper p {
  color: #b5da9d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.wallet-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount {
  /* width: 100%; */
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}
.toggle-eye-icon {
  font-size: 18px;
}
.wallet-withdraw {
  display: inline-flex;
  padding: 12px 31.5px 12px 33.5px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #f5ffee;
  height: 44px;
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;

  /* Elevations/Dark Mode/Dark 2 */
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}
.profile-status {
  border-radius: 16px;
  border: 1px solid rgba(40, 87, 128, 0.23);
  background: var(--greys-grey-10, #faf9f8);
  padding: 18px 13px 30px 24px;
}
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-header p:first-child {
  color: #201f1d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.profile-header p:last-child {
  color: var(--text-secondary, #605e5c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.profile-bars {
  display: flex;
}
.grey-bar,
.white-bar {
  height: 7px;
}
.grey-bar {
  width: 40%;
  background: #849db3;
}
.white-bar {
  border-radius: 0 43px 43px 0px;
  background: var(--greys-grey-40, #e1dfdd);
  width: 60%;
}
.verify-email {
  display: flex;
  align-items: center;
  gap: 8px;
}
.verify-email span {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: #849db3;
}

.verify-email p {
  height: 24px;
  color: var(--text-secondary, #605e5c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 5px 0;
}

.lineThrough {
  text-decoration: line-through;
}
.complete-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}
.complete-info span {
  width: 24px;
  height: 24px;
  border: 1px solid var(--greys-grey-130, #605e5c);
  border-radius: 48px;
}
.complete-info p {
  color: #849db3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  margin: 5px 0;
}
.publisher-status {
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.publisher-status-container {
  height: 150px;
  padding-inline: 10px;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  border: 1px solid rgba(40, 87, 128, 0.23);
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
}
.status-icon-wrapper {
  margin-top: 28px;
  margin-bottom: 26px;
  border-radius: 10px;
  background: #eccbc1;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.publisher-status-container p {
  text-align: center;
  color: #575353;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 30px;
}
.status-icon-wrapper.id-2 {
  background: #b5daae;
}
.status-icon-wrapper.id-3 {
  background: #bee7ed;
}
.publisher-date-display {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}
.publisher-date-display p:first-child,
.publisher-date-display p:last-child {
  color: rgba(121, 114, 114, 0.4);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 116.667% */
}
.publisher-date-display p:nth-child(2) {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 77.778% */
}
.publisher-date-display p:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 359px) {
  .publisher-status {
    gap: 18px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .publisher-status-container {
    padding-inline: 10px;
    height: 170px;
  }
  .status-icon-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .publisher-status-container p {
    text-align: center;
    font-size: 13px;
  }
  .wallet-wrapper {
    margin-bottom: 10px;
    padding: 15px;
  }
  .amount {
    font-size: 15px;
  }
  .wallet-withdraw {
    display: inline-flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 8px;
  }
  .toggle-eye-icon {
    font-size: 15px;
  }
  .profile-header p:first-child {
    font-size: 15px;
  }
  .profile-header p:last-child {
    font-size: 12px;
  }
}
@media (min-width: 359px) and (max-width: 768px) {
  .publisher-status {
    gap: 18px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .publisher-status-container {
    padding-inline: 10px;
    height: 170px;
  }
  .status-icon-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .publisher-status-container p {
    text-align: center;
    font-size: 13px;
  }
  .wallet-wrapper {
    margin-bottom: 10px;
    padding: 25px;
  }
  .amount {
    font-size: 20px;
  }
  .wallet-withdraw {
    display: inline-flex;
    padding: 12px 15px 12px 15px;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 12px;
  }
}
