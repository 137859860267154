.portalDetailsTitle {
  color: #285780;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.portalDetailsCardTitle {
  color: #285780;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 109.091% */
}

.paramTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.paramValue {
  color: #346a4e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}

.editPen {
  color: #285780;
  zoom: 1.5;
}
