.walletBalanceContainer {
  border-radius: 20px;
  border: 1px solid #285780;
  background: #f7f8fb;
  min-height: 150px;
}

.yourBalanceText {
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.yourBalanceAmount {
  color: #285780;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 62.4px */
}

.lifeTimeEarningContainer {
  border-radius: 20px;
  border: 1px solid #85625a;
  background: #f7f8fb;
  min-height: 120px;
}

.lifeTimeEarningText {
  color: #85625a;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.lifeTimeEarningTotal {
  color: #85625a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
}

.walletMenuButtonText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
}

.transferTextColor {
  color: #515251;
}

.transferContainer {
  border-radius: 20px;
  background: rgba(216, 212, 212, 0.32);
  min-height: 200px;
}

.receivedContainer {
  border-radius: 20px;
  background: rgba(63, 127, 52, 0.8);
  min-height: 200px;
}

.topUpContainer {
  border-radius: 20px;
  background: rgba(40, 87, 128, 0.84);
  min-height: 200px;
}
