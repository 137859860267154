.heroSectionbg {
  background: linear-gradient(
    180deg,
    rgba(195, 217, 234, 0.5) 0%,
    rgba(20, 49, 71, 0) 81.77%
  );
  min-height: 200px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.heroHeaderText {
  color: var(--new-styles-text-heading, #292a29);
  font-family: Bona Nova;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 112.5% */
}

.heroHeaderTextBlue {
  color: #0172d2;
}

.heroHeaderTextRed {
  color: var(--new-styles-secondary-strawberry, #ea4e4b);
}

.heroHeaderTextGold {
  color: var(--new-styles-secondary-army, #b4a572);
}

.herocontent {
  color: var(--new-styles-text-body-dark, #515251);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 140% */
  letter-spacing: 0.2px;
}

.heroRegisterButton {
  display: flex;
  width: 289px;
  max-width: 100%;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background: #285780;
  box-shadow: 0px 8px 16px 0px rgba(17, 69, 59, 0.2);
}

.heroRegisterButtonText {
  color: var(--new-styles-shades-white, #fff);
  font-family: Bona Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.advertPublisherBg {
  min-height: 200px;
}

.advertiserHeader {
  color: #285780;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 52px */
  display: contents;
}

.advertiserSubHeader {
  color: #ea7052;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 46.8px */
  display: contents;
}

.advertTextHolder {
  margin-top: 10px;
}

.advertiserText {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 26px */
}

.howItworksBg {
  min-height: 200px;
}

.howItworksTitle {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 52px */
}

.howItworkHeaderWrapper {
  position: relative;
  top: -40px;
}
.howItworkHeader {
  padding-top: 10px;
  position: relative;
  display: inline-block;
  width: 181px;
  max-width: 80%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #ea7052;
  text-align: center;
}
.howItworkHeaderText {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

.howItworkContent {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18.2px */
}
.howItWorksAdvertiserList ul {
  padding-inline: 12px;
  margin: 0;
}

.whatWeOfferBg {
  min-height: 200px;
  background: #f2f2fb;
}

.whatWeOfferHeader {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 52px */
}

.whatWeOfferTitle {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}
.whatWeOffertext {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 275;
  line-height: 130%; /* 20.8px */
}

.whatToKnowPublisherBg {
  min-height: 200px;
}

.whatToKnowPublisherHeader {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 41.6px */
}

.whatToKnowPublisherContent {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 23.4px */
}

.whatToKnowPublisherBulletHolder {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  height: 100px;
  
}
.whatToKnowPublisherBulletHolder img {
  margin-top: 10px;
}

.whatToKnowPublisherBullet {
  margin-right: 10px;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
}

.advertiserBenefitBg {
  min-height: 200px;
}

.advertiserBenefitHeader {
  color: var(--base-02, #090914);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px; /* 119.231% */
  letter-spacing: -2px;
}
.advertiserBenefitBulletHeader {
  color: var(--gray-900, #18181b);
  /* Bold/16px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: -0.2px;
}
.advertiserBenefitBulletText {
  color: var(--gray-900, #18181b);
  /* Regular/16px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.reviewBg {
  min-height: 200px;
}

.reviewCaption {
  color: var(--gray-500, #71717a);
  /* Regular/16px */
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.reviewName {
  font-family: Poppins;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.reviewJobTitle {
  font-family: Poppins;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
