.ant-radio-button-wrapper-checked {
  background-color: #285780 !important;
  border-color: #285780 !important;
}

@media only screen and (min-width: 760px) {
  .ant-radio-group {
    width: 100% !important;
  }
  .ant-radio-button-wrapper {
    width: 20% !important;
  }
}
