.notificationTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
}

.notificationSubTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.notificationIcon {
  color: #285780;
  zoom: 2;
}
