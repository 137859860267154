.sideMenuFixed {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 300px;
  z-index: 1000000;
  overflow: scroll;
}

.fixedHeader {
  position: fixed;
  left: 0px;
  width: 100%;
  height: 80px;
  z-index: 100000;
}

.content {
  padding-top: 100px;
}
