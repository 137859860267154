.aboutcardheight {
  min-height: 300px;
  padding-top: 20px;
  position: relative;
}
.about {
  position: relative;
  min-height: 200px;
}
.svg-left {
  margin-bottom: 162px;
  position: relative;
}

.svg-left img {
  position: absolute;
  left: -54px;
}
.svg-right {
  top: -230px;
  position: relative;
  min-height: 200px;
}
.svg-right img {
  position: absolute;
  z-index: 1;
  overflow: auto;
  right: 0px;
}
.about-content {
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.about-linkmie {
  color: rgba(0, 0, 0, 0.5);
  font-family: Bona Nova;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

.about-linkmie span {
  color: #d6ce8c;
}

.about-guestpost {
  color: #000;
  font-family: Bona Nova Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px; /* 240% */
}
.about-subtext {
  color: #000000;
  text-align: justify;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 177.778% */
  margin-bottom: 57px;
}

.our-focus-header {
  margin-top: 32px;
  margin-bottom: 20px;
  color: var(--base-02, #090914);
  text-align: center;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px; /* 119.231% */
  letter-spacing: -2px;
}
.our-focus {
  background: #e3e8eb;
  /* padding-bottom: 80px; */
}
.our-focus-left {
  display: flex;
  margin-right: 5px;
}
.index-focus {
  color: #36ab6c;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  margin-right: 2px;
}
.header-focus {
  margin-bottom: 20px;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 26px */
}
.our-focus-container {
  display: flex;
}
.content-focus {
  margin-bottom: 20px;
  /* margin-left: 44px; */
  color: #000000;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 19.5px */
}
.our-mission {
  margin-top: 50px;
  margin-bottom: 50px;
  /* padding: 115px 0 115px 0; */
}
.mission-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
}
.mission-icons img {
  width: 50%;
}
.quote-about img {
  width: 50%;
}
.about-elipse-top.right {
  position: absolute;
}
.about-elipse-top img {
  width: 70%;
}
.our-focus-content {
  color: #292d32;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
  text-align: left;
  margin-bottom: 5px;
  padding-inline: 10px;
  text-align: justify;
}
.quote-right {
  margin-bottom: 41px;
}
.our-focus-name {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ea7052;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 240% */
}
.rectangle-right {
  display: block;
  transform: rotate(-150.004deg);
}
.circle-right {
  margin-right: 0;
  display: block;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .our-mission {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .mission-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }
  .mission-icons img {
    width: 10%;
    margin-bottom: 20px;
  }
  .quote-about img {
    width: 20%;
  }
  .our-focus-name {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .our-focus-content {
    font-size: 12px;
  }
  .quote-right {
    margin-bottom: 41px;
  }
  .our-focus-name {
    font-size: 17px;
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  .mission-icons img {
    width: 40%;
  }
  .quote-about img {
    width: 40%;
  }
}