.footerwrapper {
  min-height: 200px;
  padding-top: 50px;
}

.logoTextFooter {
  color: #1a3b5f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
}

.briefAbout {
  margin-right: 10px;
  color: var(--gray-500, #71717a);

  /* Regular/16px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.footerHeader {
  color: var(--blue-gray-400, #94a3b8);
  /* Bold/11px CAP */
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 138.462% */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footerLink {
  color: var(--base-02, #090914);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 150% */
  text-decoration: none;
}

.copyRightText {
  color: var(--base-02, #090914);
  text-align: center;

  /* Regular/13px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.subscribeEmailInput {
  border-radius: 10px;
  border: 1px solid var(--gray-200, #e4e4e7);
  background: var(--base-white, #fff);
  width: 100%;
  height: 55px;
  font-size: 12px;
  padding: 10px;
  flex-shrink: 0;
}

.subscribeEmailButton {
  border-radius: 9px;
  background: #285780;
  display: flex;
  width: 100%;
  height: 55px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.subscribeEmailButtonText {
  color: var(--base-white, #fff);
  text-align: center;
  /* Semi-bold/16px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
