.privacy-policy-container {
  background-image: url("https://www.transparenttextures.com/patterns/ag-square.png");
}
.privacy-policy h3 {
  font-family: Bona Nova;
  text-align: center;
  color: #285780;
  font-weight: 600;
}
.privacy-policy h2 {
  font-family: Bona Nova;
  text-align: center;
  color: var(--gray-500, #71717a);
}
.privacy-policy p,
.privacy-policy ul,
.privacy-policy ol {
  font-family: Poppins;
  font-size: 12px;
  line-height: 30px;
}
.privacy-policy h4 {
  font-family: Bona Nova;
  color: var(--gray-500, #71717a);
}
.last {
  font-size: 16px;
  margin-bottom: 50px;
}
.privacy-policy li {
  margin-bottom: 10px;
}
