.leftPanel {
  width: 100%;
  height: 1117px;
}

.rightPanel {
  width: 100%;
  height: 960px;
}

.wrapperFormContainer {
  padding: 20px;
  padding-top: 30px;
}

.wrapperFormBorder {
  border: 1px solid #ccc !important;
  border-color: #fc671a !important;
}

@media (max-width: 600px) {
  .wrapperFormBorder {
    border: 0px solid #ccc !important;
  }
}

.getStartedText {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px; /* 119.231% */
  letter-spacing: -2px;
}

.getStartedUserTypeText {
  color: #090914;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
}

.verifyEmailText {
  color: rgba(9, 9, 20, 0.55);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.inputField {
  font-size: 12px;
  font-family: Poppins;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: #fcfbff;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 22px 24px;
  align-items: center;
  gap: -5px;
}

.forgetPasswordBtn {
  color: #8f3a1f;
  /* Semi-bold/16px */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration: none;
}

.submitButton {
  display: flex;
  width: 100%;
  height: 50px;
  min-height: 48px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #2e7cbe;
}

.submitButtonText {
  color: var(--white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
}

.btnGreen {
  background: #7ab495;
}

.dont-have-account-text {
  color: #101010;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dont-have-account-text-sign-up {
  color: #2e7cbe;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.acceptTNCtext {
  color: #101010;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

.acceptTNCtextLink {
  color: #2e7cbe;
}

.inputPrefix {
  color: #2e7dbea6;
}

.authImageHolder {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.inputSelect {
  font-size: 12px;
  font-family: Poppins;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: #fcfbff;
  height: 50px;
  padding-left: 22px;
}

.inputRadio {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--blue-gray-300, #cbd5e1);
  background: var(--blue-gray-50, #f8fafc);
  padding: 12px 16px;
}

.inputTopName {
  color: rgba(9, 9, 20, 0.6);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.asterisk {
  color: rgba(186, 85, 28, 0.99);
}
