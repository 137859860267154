.ant-table-thead .ant-table-cell {
  background-color: #285780 !important;
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.ant-table-tbody {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 150% */
}

.tagText {
  text-transform: capitalize;
}

.button {
  border-radius: 5px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 150% */
  margin: 5px;
}

.editButton {
  background: #7ab495;
}

.deleteButton {
  background: #ba5839;
}

.tableIcon {
  zoom: 1.5;
  padding: 5px;
}
