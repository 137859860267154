.taskDetailsTitle {
  color: #285780;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.taskDetailsCardTitle {
  color: #285780;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 109.091% */
}

.paramTitle {
  color: #515251;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.paramValue {
  color: #346a4e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}

.editPen {
  color: #285780;
  zoom: 1.5;
}

.taskBackBtn {
  border-radius: 7px;
  border: 1px solid #337f56;
  background: #f7f8fb;
  color: #337f56;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}

.taskBtnPrimaryColor {
  background: #337f56;
  color:white
}
